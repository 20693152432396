import React from 'react';
import Seo from "../components/seo";
import Adr from '../components/Adr';

const AdrPage = () => (
    <>
        <Seo title="Adr" />
        <Adr />
    </>
)

export default AdrPage;
