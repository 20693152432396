import React from 'react';
import { LeaderboardLogo } from '../Icon';
import Button from '../Button';
import cx from 'classnames';
import * as s from './s.module.css';

class Adr extends React.Component {
  render() {
    return (
      <section className={s.adrContainer}>
        <div className={s.adrContent}>
          <div className={s.row}>
            <Button to="/">
                <LeaderboardLogo className={s.leaderboardLogo} />
            </Button>
          </div>
          <div className={s.row}>
            <div className={s.titleContainer}>
              <h2 className={s.title}>Virtue Poker</h2>
            </div>
          </div>
          <div className={s.row}>
            <div>
              <h3 className={s.subtitle}>Player Complaints</h3>
              <div className={cx(s.row, s.marginBottom)}>
                <div>1</div>
                <div className={s.textContainer}>
                  Virtue Poker will endeavour to make a Player’s experience with us an enjoyable one; however, there may
                  be occasions where a Player is dissatisfied with the quality of our products or of our customer service.
                  A Player may raise a complaint by addressing an email to Customer Service at <a href="mailto:support@virtue.poker">support@virtue.poker</a>. Your
                  complaint will be escalated to management and we endeavour to resolve it within 48 hours of receipt. Should you not be satisfied with the way your complaint was resolved or handled you may escalate directly to the Malta Gaming Authority by sending them an email at <a href="mailto:support.mga@mga.org.mt">support.mga@mga.org.mt</a>
                </div>
              </div>
              <div className={cx(s.row, s.marginBottom)}>
                <div>2</div>
                <div className={s.textContainer}>
                  In the event that your complaint has not been resolved through the above procedures, you may wish to bring a claim before the Malta Arbitration Centre as a registered Alternative Dispute Resolution. Provided that the Arbitration Centre does not classify the action as vexatious or frivolous then the Company shall bear the entire costs of the action.
                </div>
              </div>
              <div className={cx(s.row, s.marginBottom)}>
                <div>3</div>
                <div className={s.textContainer}>
                  Should you feel that the company has not adequately solved, or has otherwise failed to reply to your complaint, you may escalate the issue to eCogra, which is an independent Alternative Dispute Resolution (ADR) provider which the company has engaged for such purpose. You can do so by submitting a player complaint form
                  on <a target="_blank" href={'https://ecogra.org/products-services/alternative-dispute-resolution'}> eCommerce and Online Gaming Regulation and Assurance - eCOGRA – Alternative Dispute Mediation</a>. All proceedings before the ADR provider are conducted fully in English. You may not lodge a complaint with the ADR provider prior to exhausting the internal complaint procedure outlined in clause 12.1.
                </div>
              </div>
              <div className={cx(s.row, s.marginBottom)}>
                <div>4</div>
                <div className={s.textContainer}>
                  The company shall be bound by any decision which the ADR provider gives with respect to disputes concerning amounts of up to five-thousand euro (€5,000). Any such decision shall be binding on both the company and you, and both must accept the consequences and effects emanating from such decision.
                </div>
              </div>
              <div className={cx(s.row, s.marginBottom)}>
                <div>5</div>
                <div className={s.textContainer}>
                  With respect to disputes concerning amounts exceeding €5,000, the company shall inform you beforehand, if you notify it prior to lodging your dispute, or upon being notified by the ADR provider about the receipt of such dispute, whether it accepts to be bound by the decision or otherwise.
                </div>
              </div>
              <div className={cx(s.row, s.marginBottom)}>
                If the company informs you that it will not consider an ADR decision as binding, and subsequently does not abide by such decision, you shall still have recourse to settle the dispute in the courts of Malta in accordance with the provisions of Section 2 above.
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Adr;